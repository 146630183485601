import { SelectMenu } from '@hakimo-ui/hakimo/ui-elements';
import { eventTracker } from '@hakimo-ui/hakimo/util';
import { Button, InputField, Selectable } from '@hakimo-ui/shared/ui-base';
import { XCircleIcon } from '@heroicons/react/24/solid';
import { useState } from 'react';
import { getResolveComments } from '../utils';

interface Props {
  onResolve: (message: string) => void;
  isLoading: boolean;
}

export function ResolveAction(props: Props) {
  const { onResolve, isLoading } = props;
  const [showDropdown, setShowDropdown] = useState(false);
  const [showTextField, setShowTextField] = useState<boolean>(false);
  const [comment, setComment] = useState<Selectable>({ id: '', name: '' });

  const onClickResolve = () => setShowDropdown(true);
  const onSelectComment = (val: Selectable) => {
    if (val.id === 'OTHERS') {
      setShowTextField(true);
      setComment({ id: '', name: '' });
    } else {
      setComment(val);
    }
  };
  const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setComment({ id: '', name: e.target.value });

  const onCancel = () => {
    setShowDropdown(false);
    setShowTextField(false);
    setComment({ id: '', name: '' });
  };

  if (!showDropdown) {
    return (
      <div className="text-center">
        <Button variant="success" onClick={onClickResolve}>
          Resolve
        </Button>
      </div>
    );
  }

  return (
    <div className="flex items-center gap-2">
      {showTextField ? (
        <InputField
          label=""
          onChange={onTextFieldChange}
          type="text"
          value={comment?.name || ''}
          placeholder="Please specify"
          autoFocus
        />
      ) : (
        <SelectMenu
          value={comment}
          placeholder="Select a comment"
          items={getResolveComments()}
          displayValue={(item) => item?.name ?? ''}
          onChange={onSelectComment}
          id={(item) => item?.id || ''}
        />
      )}
      <Button
        title="Confirm"
        variant="primary"
        loading={isLoading}
        disabled={comment.id === 'blank' || isLoading}
        onClick={() => onResolve(comment?.name ?? '')}
        onSideEffect={eventTracker('resolve_apartment_monitoring_escalation')}
      >
        Confirm
      </Button>
      <Button variant="icon" onClick={onCancel}>
        <XCircleIcon className="h-5 w-5" />
      </Button>
    </div>
  );
}
