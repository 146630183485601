/* eslint-disable max-lines */
import {
  SOPTalkdown,
  SOPTalkdownType,
  SOPWorkflow,
} from '@hakimo-ui/hakimo/types';
import {
  Checkbox,
  InputField,
  Label,
  Tooltip,
} from '@hakimo-ui/shared/ui-base';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { WorkflowFormListItem } from './WorkflowFormListItem';
import WorkflowFormTalkdownItem from './WorkflowFormTalkdownItem';

export interface SOPWorkflowFormItemsType {
  title: string;
  id: keyof SOPWorkflow;
  inputType: string;
}

export const SOPWorkflowFormItems: SOPWorkflowFormItemsType[] = [
  {
    title: 'Site address',
    id: 'siteAddress',
    inputType: 'string',
  },
  {
    title: 'Site Google map location',
    id: 'siteGoogleMapLocation',
    inputType: 'link',
  },
  {
    title: 'Site Exceptions',
    id: 'exceptions',
    inputType: 'list',
  },
  {
    title: 'Talkdown Enabled',
    id: 'isTalkdownEnabled',
    inputType: 'boolean',
  },
  {
    title: 'First Talkdown',
    id: 'firstTalkdown',
    inputType: 'talkdown',
  },
  {
    title: 'Second Talkdown',
    id: 'secondTalkdown',
    inputType: 'talkdown',
  },
  {
    title: 'Points to remember while escalation',
    id: 'escalationPoints',
    inputType: 'list',
  },
];

export const talkdownTypeInfo = `Static Talkdown: Delivers full sentences with immediate text-to-speech playback, requiring no user input.\n
Dynamic Talkdown: Requires user input, such as shirt color, to customize responses. Supports both text-to-speech and recorded delivery.`;

export const getFormData = (
  isEditing: boolean,
  editedData: SOPWorkflow,
  initData?: SOPWorkflow
) => {
  const isTalkdownEnabled = isEditing
    ? editedData.isTalkdownEnabled
    : initData?.isTalkdownEnabled;
  return isTalkdownEnabled
    ? SOPWorkflowFormItems
    : SOPWorkflowFormItems.filter((item) => item.inputType !== 'talkdown');
};

const getNonEditableNodes = (
  data: SOPWorkflow,
  itemId: keyof SOPWorkflow,
  itemType: string
) => {
  switch (itemType) {
    case 'link': {
      const val = data[itemId] as string;
      return (
        <a
          href={val}
          target="_blank"
          rel="noreferrer"
          className="text-primary-500"
        >
          {val}
        </a>
      );
    }

    case 'boolean': {
      const val = data[itemId] as boolean;
      return <Checkbox checked={val} />;
    }
    case 'list': {
      const values = data[itemId] as string[];
      return values.length > 0 ? (
        <div className="col-span-2 space-y-2">
          {values.map((val) => (
            <li>{val}</li>
          ))}
        </div>
      ) : (
        <div>Not applicable</div>
      );
    }
    case 'talkdown': {
      const val = data[itemId] as SOPTalkdown;
      return (
        <div className="col-span-2 space-y-2 ">
          <span className="flex items-center gap-2">
            <Label
              small
              text={`${val.type.toUpperCase()} Talkdown`}
              type="info"
            />
            <Tooltip
              text={talkdownTypeInfo}
              colorModifier="info"
              position="top-right"
              size="large"
            >
              <InformationCircleIcon className="h-5 w-5" />
            </Tooltip>
          </span>

          <div>{val.text}</div>
        </div>
      );
    }
    default: // for case type: string
      return <span>{data[itemId] as string}</span>;
  }
};

const getEditableNodes = (
  data: SOPWorkflow,
  itemId: keyof SOPWorkflow,
  itemType: string,
  onChangeData: (val: SOPWorkflow) => void
) => {
  const valueUpdater = (
    id: keyof SOPWorkflow,
    value: string | string[] | boolean | SOPTalkdown
  ) => {
    const updatedData = { ...data, [id]: value };
    onChangeData(updatedData);
  };

  switch (itemType) {
    case 'boolean': {
      const val = data[itemId] as boolean;
      return (
        <Checkbox
          checked={val}
          onChange={(checked: boolean) => valueUpdater(itemId, checked)}
        />
      );
    }

    case 'list': {
      const values = data[itemId] as string[];
      return (
        <WorkflowFormListItem
          items={values}
          onChange={(items) => valueUpdater(itemId, items)}
        />
      );
    }

    case 'talkdown': {
      const val = data[itemId] as SOPTalkdown;
      return (
        <WorkflowFormTalkdownItem
          talkdown={val}
          onChange={(updatedVal) => valueUpdater(itemId, updatedVal)}
        />
      );
    }

    default: {
      // case: link and string
      const dataVal = data[itemId] as string;
      return (
        <InputField
          type="text"
          value={dataVal}
          onChange={(e) => valueUpdater(itemId, e.target.value)}
        />
      );
    }
  }
};

export const getWorkflowFormItems = (
  isEdit: boolean,
  itemId: keyof SOPWorkflow,
  itemType: string,
  updatedData: SOPWorkflow,
  onChangeUpdatedData: (val: SOPWorkflow) => void,
  data?: SOPWorkflow
) => {
  if (isEdit) {
    return getEditableNodes(updatedData, itemId, itemType, onChangeUpdatedData);
  } else {
    return data ? getNonEditableNodes(data, itemId, itemType) : '';
  }
};

export const getInitialData = () => {
  return {
    exceptions: [''],
    firstTalkdown: {
      type: SOPTalkdownType.STATIC,
      text: '',
    },
    secondTalkdown: {
      type: SOPTalkdownType.DYANMIC,
      text: '',
    },
    siteAddress: '',
    siteGoogleMapLocation: '',
    escalationPoints: [''],
    isTalkdownEnabled: true,
  };
};

const VALIDATION_ERRORS: { [key: string]: string } = {
  REQUIRED_SITE_ADDRESS: 'Site address is required',
  REQUIRED_GOOGLE_MAP: 'Site Google map location is required',
  REQUIRED_TALKDOWN_TEXT: 'First talkdown text is required',
  REQUIRED_TALKDOWN_TEXT_SECOND: 'Second talkdown text is required',
  REQUIRED_ESCALATION_POINTS:
    'Escalation points are required. Add at least one.',
  EMPTY_ESCALATION_POINT:
    'Escalation point should not be empty. Provide info or delete the empty ones.',
  REQUIRED_EXCEPTION_POINTS:
    'Exceptions points are required. Add at least one.',
  EMPTY_EXCEPTION_POINT:
    'Exceptions point should not be empty. Provide info or delete the empty ones.',
};

export const validateFormData = (data?: SOPWorkflow) => {
  if (!data) {
    return { isValid: false, message: undefined };
  }
  let message = undefined;

  if (!data.siteAddress) {
    message = VALIDATION_ERRORS['REQUIRED_SITE_ADDRESS'];
  } else if (!data.siteGoogleMapLocation) {
    message = VALIDATION_ERRORS['REQUIRED_GOOGLE_MAP'];
  } else if (!validatePoints('Exception', data.exceptions).isValid) {
    message = validatePoints('Exception', data.exceptions).message;
  } else if (data.isTalkdownEnabled && !data.firstTalkdown.text) {
    message = VALIDATION_ERRORS['REQUIRED_TALKDOWN_TEXT'];
  } else if (data.isTalkdownEnabled && !data.secondTalkdown.text) {
    message = VALIDATION_ERRORS['REQUIRED_TALKDOWN_TEXT_SECOND'];
  } else if (!validatePoints('Escalation', data.escalationPoints).isValid) {
    message = validatePoints('Escalation', data.escalationPoints).message;
  }

  return { isValid: !message, message };
};

const validatePoints = (type: string, points: string[]) => {
  if (points.length === 0 && type === 'Escalation') {
    return {
      isValid: false,
      message: VALIDATION_ERRORS[`REQUIRED_${type.toUpperCase()}_POINTS`],
    };
  }
  if (points.some((point) => point === '')) {
    return {
      isValid: false,
      message: VALIDATION_ERRORS[`EMPTY_${type.toUpperCase()}_POINT`],
    };
  }
  return { isValid: true, message: '' };
};
