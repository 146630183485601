import { useUpdateSOP } from '@hakimo-ui/hakimo/data-access';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button, Modal } from '@hakimo-ui/shared/ui-base';

interface Props {
  sopId: string;
  sopOverview: string;
  onClose: () => void;
}

export function ShowDisableWorkflowModal(props: Props) {
  const { sopId, onClose, sopOverview } = props;

  const onSuccess = () => {
    toast('SOP workflow updated successfully', { type: 'success' });
    onClose();
  };

  const mutation = useUpdateSOP(sopId, onSuccess);

  const onDisableWorkflow = () => {
    const payload = {
      sop_overview: sopOverview,
      sop_workflow: undefined,
    };
    mutation.mutate({ sop_text: JSON.stringify(payload) });
  };

  const actions = (
    <>
      <Button
        variant="error"
        onClick={onDisableWorkflow}
        loading={mutation.isLoading}
      >
        Disable Workflow
      </Button>
      <Button onClick={onClose}>Cancel</Button>
    </>
  );

  return (
    <Modal title="Disable SOP Workflow" open onClose={onClose} footer={actions}>
      <div className="p-8">
        {mutation.isError && (
          <Alert type="error">{mutation.error.message}</Alert>
        )}
        Are you sure you want to disable SOP workfow for this location ?
      </div>
    </Modal>
  );
}
