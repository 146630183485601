import { useEffect, useMemo, useRef, useState } from 'react';
import useMultiSSE from '../../hooks/useMultiSSE';
import { EventHistory } from '../../types/event';
import { EventImage } from './EventImage';

interface Props {
  visionHosts: string[];
}

export function EventsHistory(props: Props) {
  const { visionHosts } = props;
  const [eventHistory, setEventHistory] = useState<EventHistory[]>([]);
  const eventHistoryRef = useRef<EventHistory[]>([]);

  useEffect(() => {
    const intervalId = window.setInterval(() => {
      setEventHistory(eventHistoryRef.current);
    }, 1000);

    return () => {
      window.clearInterval(intervalId);
    };
  }, []);

  const onReceiveEvent = (data: EventHistory) => {
    const matchString = data.frame.match(/.{1,2}/g);
    if (!matchString) {
      return;
    }
    const frameBytes = new Uint8Array(
      matchString.map((byte: string) => parseInt(byte, 16))
    );

    // Create a Blob from the bytes
    const blob = new Blob([frameBytes], { type: 'image/webp' });

    // Create an Object URL from the Blob
    const url = URL.createObjectURL(blob);

    const currData = eventHistoryRef.current;
    const updatedData = [
      {
        id: data.id,
        p: data.p,
        name: data.name,
        frame: url,
        createdTime: data.createdTime,
      },
      ...currData,
    ];
    if (updatedData.length > 50) {
      updatedData.pop();
    }
    eventHistoryRef.current = updatedData;
  };
  const urls = useMemo(
    () => visionHosts.map((host) => `${host}/api/vision/events/history`),
    [visionHosts]
  );

  useMultiSSE(urls, onReceiveEvent);

  return (
    <div className="dark:border-ondark-line-2 min-h-[80vh] w-[210px] rounded border p-2">
      <h1 className="mb-2 text-center font-bold">Events History</h1>
      <div className="flex max-h-[90vh] max-w-full flex-col gap-2 overflow-auto">
        {eventHistory.map((event, index) => (
          <EventImage key={index} event={event} />
        ))}
      </div>
    </div>
  );
}
