import { SiteSchedule } from '@hakimo-ui/hakimo/types';
import { useEffect, useState } from 'react';
import { DayRange, convertToTimeFromMinutes, getWeekRanges } from './util';

interface Props {
  schedules: SiteSchedule[];
}

export function MonitoringWidget(props: Props) {
  const { schedules } = props;
  const [fullWeekRanges, setFullWeekRanges] = useState<DayRange[]>([]);

  useEffect(() => {
    const weekRanges = getWeekRanges(schedules);
    setFullWeekRanges(weekRanges);
  }, [schedules]);

  const barWidth = 400;
  const fullDayMinutes = 24 * 60;

  const getSectionWidth = (startTime: number, endTime: number) => {
    const sectionTimeSpanInMin = endTime - startTime;
    return Math.floor((sectionTimeSpanInMin * barWidth) / fullDayMinutes);
  };

  const getRelativePosition = (timeInMinutes: number) => {
    return (timeInMinutes * barWidth) / fullDayMinutes;
  };

  return (
    <div className="flex flex-col gap-10">
      <div className="text-center underline">Monitoring overview</div>
      {fullWeekRanges.map((day) => (
        <div className="flex items-center gap-10">
          <span className="text-sm">{day.symbol}</span>
          <div
            style={{ width: `${barWidth}px` }}
            className="dark:border-ondark-line-2 relative h-5 border"
          >
            {day.ranges.map((range) => (
              <>
                <div
                  style={{
                    width: `${getSectionWidth(
                      range.startTime,
                      range.endTime
                    )}px`,
                    left: getRelativePosition(range.startTime),
                  }}
                  className="bg-primary-300 dark:bg-primary-700 absolute top-[1px] z-10 h-4"
                ></div>
                <span
                  style={{ left: getRelativePosition(range.startTime) - 8 }}
                  className="absolute -top-4 text-xs"
                >
                  {convertToTimeFromMinutes(range.startTime)}
                </span>
                <span
                  style={{ left: getRelativePosition(range.endTime) - 8 }}
                  className="absolute -bottom-4 text-xs"
                >
                  {convertToTimeFromMinutes(range.endTime)}
                </span>
                <span className="absolute top-1 right-0 z-0 text-[8px]">
                  24:00
                </span>
                <span className="absolute top-1 left-0 z-0 text-[8px]">
                  00:00
                </span>
              </>
            ))}
          </div>
        </div>
      ))}
      <div className="dark:border-dark-border-surface flex items-center justify-center gap-4 border-t p-2">
        <div className="bg-primary-300 dark:bg-primary-700 h-5 w-5"></div>
        <span className="text-xs">Monitored Hours</span>
      </div>
    </div>
  );
}

export default MonitoringWidget;
