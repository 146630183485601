/* eslint-disable max-lines */
import { useSOP } from '@hakimo-ui/hakimo/data-access';
import {
  LocationAlarmIncidentDTO,
  LocationAlarmStatus,
  SOPWorkflow,
  StatusType,
} from '@hakimo-ui/hakimo/types';
import { loadDomPurify } from '@hakimo-ui/hakimo/util';
import { Alert, Button, HakimoSpinner, Tabs } from '@hakimo-ui/shared/ui-base';
import clsx from 'clsx';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { LocationAlarmCamera } from '../quick-actions-panel/util';
import { ResolveAction } from './ResolveAction';
import { SOPWorkflowRoot } from './SOPWorkflowRoot';
import { EscalateAlarm } from './escalate/EscalateAlarm';
import {
  SOPTab,
  WorkflowItemType,
  currentStepAtom,
  isSOPWorkflowEnabledAtom,
  sopTabs,
} from './util';

interface Props {
  locationId: string;
  locationAlarmId: string;
  alarmCameras: LocationAlarmCamera[];
  onAlarmResolve: (status: StatusType) => void;
  locationAlarmStatus: LocationAlarmStatus;
  locationAlarmIncident?: LocationAlarmIncidentDTO;
  locationTenantId?: string;
}

export function SOPWorkflowContainer(props: Props) {
  const {
    locationId,
    locationAlarmId,
    alarmCameras,
    onAlarmResolve,
    locationAlarmIncident,
    locationAlarmStatus,
    locationTenantId,
  } = props;
  const [sopWorkflow, setSOPWorkflow] = useState<SOPWorkflow>();
  const [sanitizedHtml, setSanitizedHtml] = useState<string>('');
  const [activeTab, setActiveTab] = useState(SOPTab.SOP_WORKFLOW);
  const [isQuickResolve, setIsQuickResolve] = useState<boolean | undefined>(
    false
  );
  const [isSOPWorkflowEnabled, setIsSOPWorkflowEnabled] = useAtom(
    isSOPWorkflowEnabledAtom
  );
  const { isLoading, isRefetching, isError, error } = useSOP(
    {
      locationId,
    },
    async ({ sop_text, sop_workflow: workflow }) => {
      const domPurify = await loadDomPurify();
      setSanitizedHtml(domPurify(sop_text));
      setSOPWorkflow(workflow);
      setIsSOPWorkflowEnabled(workflow !== undefined);
    },
    () => {
      setIsSOPWorkflowEnabled(false);
    }
  );
  const isIncidentTriggeringAlarm =
    locationAlarmIncident &&
    locationAlarmIncident.initiatingLocationAlarmId === locationAlarmId;
  const [currentWorkflowItemType, setCurrentWorkflowItemType] = useState<
    WorkflowItemType | undefined
  >(
    isIncidentTriggeringAlarm
      ? WorkflowItemType.ESCALATE
      : WorkflowItemType.STEPS
  );

  const setWorkflowCurrentStep = useSetAtom(currentStepAtom);

  useEffect(() => {
    setWorkflowCurrentStep(0);
  }, [setWorkflowCurrentStep, locationAlarmId]);

  useEffect(() => {
    if (locationAlarmStatus === 'Resolved') {
      setCurrentWorkflowItemType(undefined);
    }
  }, [locationAlarmStatus]);

  const onChangeTab = (tabId: SOPTab) => {
    setActiveTab(tabId);
  };

  const navigateTo = (val: WorkflowItemType) => () => {
    setCurrentWorkflowItemType(val);
  };

  const navigateToResolve = (isQuickResolveVal?: boolean) => {
    navigateTo(WorkflowItemType.RESOLVE)();
    setIsQuickResolve(isQuickResolveVal === true); // explicitly checking with true because in other cases click event data is coming
  };

  const renderSOPOverview = () => (
    <ul className="dark:bg-ondark-bg-2 bg-onlight-bg-2 mt-1 space-y-4 p-3">
      <div
        className="whitespace-pre-wrap text-sm"
        dangerouslySetInnerHTML={{
          __html: sanitizedHtml,
        }}
      />
    </ul>
  );

  if (!isSOPWorkflowEnabled) {
    return (
      <div>
        <h2 className="flex h-9 items-center">Standard Operating Procedure</h2>
        {isError && (
          <div className="mx-auto max-w-screen-2xl p-6">
            <Alert type="error">{error.message ?? ''}</Alert>
          </div>
        )}
        {renderSOPOverview()}
      </div>
    );
  }

  return (
    <div className="mt-4 min-h-[40rem] p-5">
      <Tabs items={sopTabs} activeTabId={activeTab} onChange={onChangeTab} />
      {isError && (
        <div className="mx-auto max-w-screen-2xl p-6">
          <Alert type="error">{error.message ?? ''}</Alert>
        </div>
      )}
      {(isLoading || isRefetching) && (
        <div className="flex justify-center p-2">
          <HakimoSpinner />
        </div>
      )}
      {activeTab === SOPTab.SOP_WORKFLOW && sopWorkflow && (
        <div
          className={clsx(
            'mt-6',
            currentWorkflowItemType === WorkflowItemType.STEPS &&
              'flex min-h-[35rem] flex-col items-center justify-between gap-8'
          )}
        >
          {locationAlarmStatus === 'Resolved' && (
            <div>This location alarm is already resolved</div>
          )}
          {currentWorkflowItemType === WorkflowItemType.STEPS &&
            alarmCameras.length > 0 && (
              <SOPWorkflowRoot
                navigateToEscalate={navigateTo(WorkflowItemType.ESCALATE)}
                navigateToResolve={navigateToResolve}
                sopWorkflow={sopWorkflow}
                alarmCameras={alarmCameras}
                locationAlarmId={locationAlarmId}
                locationId={locationId}
              />
            )}
          {currentWorkflowItemType === WorkflowItemType.RESOLVE && (
            <ResolveAction
              onClose={navigateTo(WorkflowItemType.STEPS)}
              locationAlarmId={locationAlarmId}
              onAlarmResolve={onAlarmResolve}
              isQuickResolve={isQuickResolve}
            />
          )}
          {currentWorkflowItemType === WorkflowItemType.ESCALATE && (
            <EscalateAlarm
              escalationPoints={sopWorkflow.escalationPoints}
              isEscalated={!!locationAlarmIncident}
              locationAlarmId={locationAlarmId}
              locationId={locationId}
              siteAddress={sopWorkflow.siteAddress}
              siteGoogleMapLocation={sopWorkflow.siteGoogleMapLocation}
              locationTenantId={locationTenantId}
              onCancel={navigateTo(WorkflowItemType.STEPS)}
            />
          )}
          {currentWorkflowItemType === WorkflowItemType.STEPS && (
            <Button
              variant="error"
              onClick={navigateTo(WorkflowItemType.ESCALATE)}
            >
              Escalate
            </Button>
          )}
        </div>
      )}
      {activeTab === SOPTab.SOP_TEXT && renderSOPOverview()}
    </div>
  );
}
