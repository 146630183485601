// TODO: remove it once testing is done in staging

import { SEPARATOR } from '@hakimo-ui/hakimo/data-access';
import { Selectable } from '@hakimo-ui/shared/ui-base';

export const getResolveComments = () => {
  return [
    { id: 'GUARD', name: 'Guards intervention' },
    { id: 'PD', name: 'PD intervention' },
    { id: 'PERSON_LEFT', name: 'Person left the site' },
    { id: 'FALSE', name: 'False escalation' },
    { id: 'OTHERS', name: 'Others' },
  ];
};

export const getQueryParams = (visionTenants: Selectable[]) => {
  const params = new URLSearchParams();
  if (visionTenants && visionTenants.length > 0) {
    const tenantIds = visionTenants.map((v) => v.id);
    params.append('tenantIds', tenantIds.join(SEPARATOR));
  }
  return params.toString();
};
