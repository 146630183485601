import { HakimoLogo } from '@hakimo-ui/hakimo/ui-navigation';
import {
  ChatBubbleLeftEllipsisIcon,
  PhoneArrowUpRightIcon,
  SpeakerWaveIcon,
} from '@heroicons/react/24/outline';
import {
  CheckIcon,
  ClockIcon,
  ExclamationCircleIcon,
  EyeIcon,
} from '@heroicons/react/24/solid';

export const checkTimelineIcon = (
  <span className="flex h-8 w-8 items-center justify-center rounded-full bg-green-500">
    <CheckIcon className="w-5 text-white" aria-hidden="true" />
  </span>
);

export const viewTimelineIcon = (
  <span className="flex h-8 w-8 items-center justify-center rounded-full bg-yellow-500">
    <EyeIcon className="w-5 text-white" aria-hidden="true" />
  </span>
);
export const hakimoTimelineIcon = (
  <span className="flex h-8 w-8 items-center justify-center rounded-full">
    <HakimoLogo />
  </span>
);
export const inProgressTimelineIcon = (
  <span className="flex h-8 w-8 items-center justify-center rounded-full bg-yellow-500">
    <ClockIcon className="w-5 text-white" aria-hidden="true" />
  </span>
);

export const pendingTimelineIcon = (
  <span className="flex h-8 w-8 items-center justify-center rounded-full bg-red-500">
    <ExclamationCircleIcon className="w-5 text-white" aria-hidden="true" />
  </span>
);

export const commentTimelineIcon = (
  <span className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-500">
    <ChatBubbleLeftEllipsisIcon className="w-5 text-white" aria-hidden="true" />
  </span>
);

export const speakerTalkdownTimelineIcon = (
  <span className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-500">
    <SpeakerWaveIcon className="w-5 text-white" aria-hidden="true" />
  </span>
);

export const callMadeIcon = (
  <span className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-500">
    <PhoneArrowUpRightIcon className="w-5 text-white" aria-hidden="true" />
  </span>
);
