import { useUpdateLocationAlarm } from '@hakimo-ui/hakimo/data-access';
import { StatusType } from '@hakimo-ui/hakimo/types';
import { toast } from '@hakimo-ui/hakimo/util';
import StatusStepper from '../../location-alarms/alarm-overview/alarm-status/status-stepper/StatusStepper';
import { DetectionType } from '../../location-alarms/alarm-overview/alarm-status/status-stepper/util';

interface Props {
  onClose: () => void;
  locationAlarmId: string;
  onAlarmResolve: (status: StatusType) => void;
  isQuickResolve?: boolean;
}

export function ResolveAction(props: Props) {
  const { onClose, locationAlarmId, onAlarmResolve, isQuickResolve } = props;

  const mutation = useUpdateLocationAlarm(locationAlarmId, () => {
    toast('Alarm status updated', { type: 'success' });
    onAlarmResolve(StatusType.RESOLVED);
  });

  const onSubmit = (comment: string) => {
    mutation.mutate({ status: StatusType.RESOLVED, comment });
  };

  return (
    <div className="divide-onlight-line-3 dark:divide-ondark-line-3 space-y-6 divide-y">
      <span>Resolve the alarm</span>
      <div className="pt-5">
        <StatusStepper
          onClose={onClose}
          onSubmit={onSubmit}
          isSubmitInProgress={mutation.isLoading}
          initialDetectionType={
            isQuickResolve ? DetectionType.INCORRECT_DETECTION : undefined
          }
        />
      </div>
    </div>
  );
}
