import { Spinner } from '@hakimo-ui/shared/ui-base';
import { LABEL_PANEL_WIDTH, MARGIN, TIMELINE_V_SPACE } from './constants';

interface Props {
  onLoadNearbyAlarms?: (seconds: number) => void;
  isFetchingAlarms?: boolean;
  containerWidth: number;
}
export function LoadExtraAlarms(props: Props) {
  const { onLoadNearbyAlarms, isFetchingAlarms, containerWidth } = props;

  const onLoadPrevAlarms = () => onLoadNearbyAlarms && onLoadNearbyAlarms(-30);

  const onLoadNextAlarms = () => onLoadNearbyAlarms && onLoadNearbyAlarms(30);

  return (
    <>
      <g onClick={onLoadPrevAlarms} cursor="pointer">
        <title>Load previous 30 seconds alarms</title>
        <rect
          x={LABEL_PANEL_WIDTH - 30}
          y={TIMELINE_V_SPACE / 2 - 1}
          width="24"
          height="24"
          className="fill-status-yellow"
          rx="40"
          ry="40"
        />
        <text
          x={LABEL_PANEL_WIDTH - 18}
          y={2 + MARGIN.top / 2}
          font-size="9"
          fill="white"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          -30s
        </text>
      </g>
      {isFetchingAlarms && (
        <foreignObject
          width={12}
          height={12}
          x={containerWidth / 2}
          y={TIMELINE_V_SPACE / 2}
        >
          <Spinner className="dark:text-hakimo-yellow text-primary-700 h-3 w-3 animate-spin" />
        </foreignObject>
      )}
      <g onClick={onLoadNextAlarms} cursor="pointer">
        <title>Load next 30 seconds alarms</title>
        <rect
          x={containerWidth + 4}
          y={TIMELINE_V_SPACE / 2 - 1}
          width="25"
          height="25"
          className="fill-status-yellow"
          rx="40"
          ry="40"
        />
        <text
          x={containerWidth + 16}
          y={2 + MARGIN.top / 2}
          font-size="9"
          fill="white"
          textAnchor="middle"
          alignmentBaseline="middle"
        >
          +30s
        </text>
      </g>
    </>
  );
}
