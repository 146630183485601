import { trackEvent } from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { useCallback, useState } from 'react';
import { useCameras } from '../hooks/useCameras';
import { Camera } from '../types/camera';
import { ScanMode } from '../types/common';
import LiveCameraFeed from './LiveCameraFeed';

interface Props {
  tenantId: string;
  visionHosts: string[];
  scanMode: ScanMode;
  onEscalate: (locationId: string, camId: string) => void;
}

const LiveFeeds = (props: Props) => {
  const { tenantId, visionHosts, scanMode, onEscalate } = props;
  const [errorState, setErrorState] = useState({
    isError: false,
    message: '',
  });

  const onError = useCallback((message: string) => {
    setErrorState({ isError: true, message: message });
  }, []);

  const onNewEvent = useCallback(
    (cameraId: string) => {
      trackEvent('event_received', {
        cameraId,
        tenantId,
      });
    },
    [tenantId]
  );

  const [state, removeCamera, snoozeCamera] = useCameras(
    visionHosts,
    onError,
    onNewEvent
  );
  const { cameras, cameraViewPropertiesMap, mostFrequentIds } = state;

  const onClickSafe = (cameraId: string) => {
    trackEvent('event_marked_safe', {
      cameraId,
      tenantId,
    });
    removeCamera(cameraId);
  };

  const onClickSnooze = (cameraId: string) => {
    trackEvent('event_snoozed', {
      cameraId,
      tenantId,
    });
    snoozeCamera(cameraId);
  };

  const onClickEscalate = (locationId: string, cameraId: string) => {
    trackEvent('event_escalated', {
      cameraId,
      tenantId,
    });
    onEscalate(locationId, cameraId);
  };

  return (
    <div>
      {errorState.isError && <Alert type="error">{errorState.message}</Alert>}
      <div className="flex flex-wrap gap-2">
        {cameras.map((camera: Camera, index: number) => (
          <div
            key={`${camera.id}-${index}`}
            className={`w-[320px] grow`}
            style={{ order: cameraViewPropertiesMap[camera.id].order }}
          >
            <LiveCameraFeed
              cameraName={camera.name}
              livestreamUrl={camera.livestreamUrl}
              latestEventTime={
                cameraViewPropertiesMap[camera.id].latestEventTime
              }
              scanMode={scanMode}
              state={
                cameraViewPropertiesMap[camera.id].isVisible
                  ? 'active'
                  : mostFrequentIds.has(camera.id)
                  ? 'active-hidden'
                  : 'inactive'
              }
              onClickSafe={() => onClickSafe(camera.id)}
              onClickSnooze={() => onClickSnooze(camera.id)}
              onClickEscalate={() =>
                onClickEscalate(camera.locationId, camera.id)
              }
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default LiveFeeds;
