import { VisionHostsReponse } from '@hakimo-ui/hakimo/types';
import useAuthenticatedRequest from '../shared/useAuthenticatedRequest';

export function useVisonHosts(queryParams: string) {
  const url = `/v2/orm/vision_hosts?${queryParams}`;
  const request = new Request(url);

  return useAuthenticatedRequest<VisionHostsReponse>(request, {
    queryKey: ['vision_hosts', queryParams],
    responseModifier: async (response) => {
      const respJson = await response.json();
      return respJson.payload;
    },
  });
}

export default useVisonHosts;
