import { MAX_ACTIVE_STREAMS, STREAM_SNOOZE_DURATION } from '../config';
import { Camera } from '../types/camera';
import { PriorityQueue } from './priority-queue';
import { CameraViewProperties, State, StateManager } from './types';

export function createStateManager(): StateManager {
  let cameras: Camera[] = [];
  let newEventCallback: (cameraId: string) => void;

  const cameraViewPropertiesMap: Record<string, CameraViewProperties> = {};
  const detectionFrequency = new PriorityQueue<Camera>();

  function getState(): State {
    const mostFrequentIds = new Set(
      detectionFrequency
        .getMaxItems(MAX_ACTIVE_STREAMS)
        .filter((queueItem) => queueItem.priority > 0)
        .map((queueItem) => queueItem.item.id)
    );

    return {
      cameras,
      cameraViewPropertiesMap,
      mostFrequentIds,
    };
  }

  function updateCameras(cams: Camera[]) {
    cameras = cams;

    cameras.forEach((camera, index) => {
      cameraViewPropertiesMap[camera.id] = {
        order: index,
        isVisible: false,
        isSnoozed: false,
        latestEventTime: 0,
      };
      detectionFrequency.insert(camera, 0);
    });
  }

  function isVisible(cameraId: string): boolean {
    return cameraViewPropertiesMap[cameraId].isVisible;
  }

  function hideCamera(cameraId: string) {
    cameraViewPropertiesMap[cameraId].isVisible = false;
  }

  function incrementPriority(cameraId: string) {
    detectionFrequency.incrementPriority(cameraId);
  }

  function updateCameraOrder(cameraId: string) {
    const currentOrder = cameraViewPropertiesMap[cameraId].order;

    let lowestOrder = currentOrder;
    let lowestOrderCameraId = cameraId;

    cameras.forEach((camera) => {
      const order = cameraViewPropertiesMap[camera.id].order;
      if (
        order < lowestOrder &&
        !cameraViewPropertiesMap[camera.id].isVisible
      ) {
        lowestOrder = order;
        lowestOrderCameraId = camera.id;
      }
    });

    if (lowestOrder !== currentOrder) {
      cameraViewPropertiesMap[lowestOrderCameraId].order = currentOrder;
      cameraViewPropertiesMap[cameraId].order = lowestOrder;
    }
  }

  function receivedEvent(cameraId: string) {
    if (!cameraViewPropertiesMap[cameraId]) {
      return;
    }

    const snoozed = isSnoozed(cameraId);

    if (snoozed) {
      return;
    }

    incrementPriority(cameraId);
    const visible = isVisible(cameraId);

    if (!visible) {
      cameraViewPropertiesMap[cameraId].isVisible = true;
      updateCameraOrder(cameraId);
      newEventCallback && newEventCallback(cameraId);
    }

    cameraViewPropertiesMap[cameraId].latestEventTime = Date.now();
  }

  function snoozeCamera(cameraId: string) {
    cameraViewPropertiesMap[cameraId].isVisible = false;
    cameraViewPropertiesMap[cameraId].isSnoozed = true;
    detectionFrequency.setPriority(cameraId, 0);

    window.setTimeout(() => {
      cameraViewPropertiesMap[cameraId].isSnoozed = false;
    }, STREAM_SNOOZE_DURATION * 1000);
  }

  function isSnoozed(cameraId: string) {
    return cameraViewPropertiesMap[cameraId].isSnoozed;
  }

  function onNewEvent(callback: (cameraId: string) => void) {
    newEventCallback = callback;
  }

  return {
    getState,
    updateCameras,
    hideCamera,
    receivedEvent,
    snoozeCamera,
    onNewEvent,
  };
}
