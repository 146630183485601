import { useSOP } from '@hakimo-ui/hakimo/data-access';
import { SOPDTO } from '@hakimo-ui/hakimo/types';
import { loadDomPurify } from '@hakimo-ui/hakimo/util';
import { Alert, HakimoSpinner } from '@hakimo-ui/shared/ui-base';
import { useState } from 'react';
import SOPOverview from './SOPOverview';
import SOPWorkflowView from './sop-workflow/SOPWorkflowView';

interface Props {
  locationId: string;
}

export function SOPRoot(props: Props) {
  const { locationId } = props;
  const [sop, setSOP] = useState<SOPDTO>();
  const { isLoading, isRefetching, isError, error } = useSOP(
    {
      locationId,
    },
    async ({ sop_text, sop_workflow, id }) => {
      const domPurify = await loadDomPurify();
      setSOP({ sop_text: domPurify(sop_text), sop_workflow, id });
    }
  );

  if (isLoading || isRefetching) {
    return (
      <span className="flex h-[26rem] w-full items-center justify-center">
        <HakimoSpinner />
      </span>
    );
  }

  return (
    <>
      {isError && <Alert type="error">{error.message}</Alert>}
      {sop && (
        <div className="grid grid-cols-2 gap-4 divide-x">
          <SOPOverview
            sopOverview={sop?.sop_text || ''}
            sopId={sop?.id || ''}
            sopWorkflow={sop?.sop_workflow}
          />
          <SOPWorkflowView
            sopWorkflow={sop?.sop_workflow}
            sopId={sop?.id || ''}
            sopOverview={sop?.sop_text || ''}
          />
        </div>
      )}
    </>
  );
}

export default SOPRoot;
