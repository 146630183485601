import { useSOP, useUpdateScanEscalation } from '@hakimo-ui/hakimo/data-access';
import {
  ScanTenantSOP,
  UpdateEscalationRequest,
} from '@hakimo-ui/hakimo/types';
import { LoadingIndicator } from '@hakimo-ui/hakimo/ui-elements';
import { toast } from '@hakimo-ui/hakimo/util';
import { Alert, Button } from '@hakimo-ui/shared/ui-base';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { DispatchType } from '../../types/sop';
import DispatchView from './DispatchView';
import { ResolveAction } from './ResolveAction';
import SOPInfo from './SOPInfo';

interface Props {
  onResolveEscalation: () => void;
  escalationId: string;
  locationId: string;
  onCancel: () => void;
}

export function ScanSOP(props: Props) {
  const { onResolveEscalation, escalationId, locationId, onCancel } = props;
  const [scanSOP, setScanSOP] = useState<ScanTenantSOP | undefined>(undefined);

  const {
    data: sopData,
    isLoading: isSOPLoading,
    isError: isLoadSOPError,
    error: loadSOPError,
  } = useSOP({ locationId });

  const [dispatchType, setDispatchType] = useState<DispatchType | undefined>(
    undefined
  );

  const { isSuccess, mutate, isError, error, isLoading } =
    useUpdateScanEscalation(escalationId);

  useEffect(() => {
    if (isSuccess) {
      toast('Escalation resolved successfully');
      onResolveEscalation();
    }
  }, [isSuccess, onResolveEscalation]);

  useEffect(() => {
    if (sopData && sopData.scan_sop) {
      setScanSOP(sopData.scan_sop);
    }
  }, [sopData]);

  const onDispatchPD = () => setDispatchType(DispatchType.PD);
  const onDispatchGuard = () => setDispatchType(DispatchType.GUARD);
  const onCancelDispatch = () => setDispatchType(undefined);
  const getDispatchContacts = () => {
    return dispatchType === DispatchType.PD
      ? scanSOP?.emergencyContact
      : scanSOP?.nonEmergencyContact;
  };

  const onResolve = (message: string) => {
    const payload: UpdateEscalationRequest = {
      escalation_id: escalationId,
      resolution_comment: message,
      status: 'Resolved',
    };
    mutate(payload);
  };

  return (
    <div className="dark:bg-ondark-bg-2 bg-onlight-bg-2 relative mt-2 min-h-[30rem] rounded p-4">
      <div className="absolute top-1 right-1">
        <Button variant="icon" onClick={onCancel}>
          <XMarkIcon className="h-5 w-5" />
        </Button>
      </div>
      <h2 className="text-center font-bold">
        Standard Operating Procedure (SOP)
      </h2>
      {isError && <Alert type="error">{error.message}</Alert>}
      {isLoadSOPError && <Alert type="error">{loadSOPError.message}</Alert>}
      {isSOPLoading && (
        <div className="my-4 flex h-full w-full items-center justify-center">
          <LoadingIndicator />
        </div>
      )}
      {!dispatchType && scanSOP && (
        <>
          <SOPInfo
            address={scanSOP.address}
            emergencySituations={scanSOP.emergencySituations}
            exceptions={scanSOP.exceptions}
            nonEmergencySituations={scanSOP.nonEmergencySituations}
          />
          <div className="my-4 flex items-center justify-around">
            <Button onClick={onDispatchPD} variant="error">
              Dispatch PD
            </Button>
            <Button onClick={onDispatchGuard} variant="outline">
              Notify Security
            </Button>
          </div>
        </>
      )}
      {dispatchType && scanSOP && (
        <DispatchView
          address={scanSOP.address}
          contacts={getDispatchContacts() || []}
          onCancel={onCancelDispatch}
        />
      )}
      {scanSOP && (
        <div className="my-12">
          <ResolveAction onResolve={onResolve} isLoading={isLoading} />
        </div>
      )}
    </div>
  );
}

export default ScanSOP;
