export class PriorityQueue<
  T extends {
    id: string;
  }
> {
  private heap: { item: T; priority: number }[];

  constructor() {
    this.heap = [];
  }

  private parent(index: number): number {
    return Math.floor((index - 1) / 2);
  }

  private leftChild(index: number): number {
    return 2 * index + 1;
  }

  private rightChild(index: number): number {
    return 2 * index + 2;
  }

  private swap(index1: number, index2: number): void {
    const temp = this.heap[index1];
    this.heap[index1] = this.heap[index2];
    this.heap[index2] = temp;
  }

  private heapifyUp(index: number): void {
    let currentIndex = index;
    while (
      currentIndex > 0 &&
      this.heap[this.parent(currentIndex)].priority <
        this.heap[currentIndex].priority
    ) {
      this.swap(currentIndex, this.parent(currentIndex));
      currentIndex = this.parent(currentIndex);
    }
  }

  private heapifyDown(index: number): void {
    let largest = index;
    const left = this.leftChild(index);
    const right = this.rightChild(index);

    if (
      left < this.heap.length &&
      this.heap[left].priority > this.heap[largest].priority
    ) {
      largest = left;
    }

    if (
      right < this.heap.length &&
      this.heap[right].priority > this.heap[largest].priority
    ) {
      largest = right;
    }

    if (largest !== index) {
      this.swap(index, largest);
      this.heapifyDown(largest);
    }
  }

  private resetPriorities(): void {
    for (let i = 0; i < this.heap.length; i++) {
      this.heap[i].priority = 0;
    }
  }

  public insert(item: T, priority: number): void {
    this.heap.push({ item, priority });
    this.heapifyUp(this.heap.length - 1);
  }

  public extractMax(): { item: T; priority: number } | null {
    if (this.heap.length === 0) {
      return null;
    }

    const root = this.heap[0];
    const lastItem = this.heap.pop();

    if (this.heap.length > 0 && lastItem) {
      this.heap[0] = lastItem;
      this.heapifyDown(0);
    }

    return root;
  }

  public getMaxItems(x: number): { item: T; priority: number }[] {
    const result: { item: T; priority: number }[] = [];
    const tempHeap = [...this.heap];

    for (let i = 0; i < x; i++) {
      const max = this.extractMax();
      if (max) {
        result.push(max);
      }
    }

    this.heap = tempHeap;
    return result;
  }

  public incrementPriority(id: string): void {
    const index = this.heap.findIndex((heapItem) => heapItem.item.id === id);

    if (index === -1) {
      return;
    }

    if (this.heap[index].priority >= Number.MAX_SAFE_INTEGER) {
      this.resetPriorities();
    }

    this.heap[index].priority += 1;
    this.heapifyUp(index);
  }

  public setPriority(id: string, priority: number): void {
    const index = this.heap.findIndex((heapItem) => heapItem.item.id === id);

    if (index === -1) {
      return;
    }

    this.heap[index].priority = priority;
    this.heapifyUp(index);
  }
}
