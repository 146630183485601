import { useLocationContacts } from '@hakimo-ui/hakimo/data-access';
import { QueryResult } from '@hakimo-ui/hakimo/ui-elements';
import ContactConnect from '../../../alarm-details/contact-connect/ContactConnect';

interface Props {
  locationId: number;
  locationAlarmId: string;
  locationTenantId?: string;
}

export function LocationAlarmContacts(props: Props) {
  const { locationId, locationAlarmId, locationTenantId } = props;
  const queryResult = useLocationContacts(locationId);
  return (
    <div className="space-y-1">
      <h2 className="flex h-9 items-center">Location Contacts</h2>
      <QueryResult queryResult={queryResult}>
        {(contacts) => {
          const sortedContacts = contacts.sort(
            (a, b) => b.priority - a.priority
          );

          return (
            <div className="dark:divide-ondark-line-2 dark:bg-ondark-bg-2 bg-onlight-bg-2 divide-onlight-line-2 divide-y p-4">
              {sortedContacts.map((contact) => (
                <ContactConnect
                  toNumber={contact.phoneNumber}
                  toName={contact.name}
                  key={contact.id}
                  locationAlarmId={locationAlarmId}
                  locationTenantId={locationTenantId}
                />
              ))}
            </div>
          );
        }}
      </QueryResult>
    </div>
  );
}
