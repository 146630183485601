import {
  FullScreenExitIcon,
  FullScreenIcon,
} from '@hakimo-ui/hakimo/ui-elements';
import { useFullscreen } from '@hakimo-ui/hakimo/util';
import { useRef } from 'react';
import { EventHistory } from '../../types/event';

interface Props {
  event: EventHistory;
}

export function EventImage(props: Props) {
  const { event } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const { isFullScreen, toggleFullScreen } = useFullscreen(containerRef);

  return (
    <div
      ref={containerRef}
      className="bg-onlight-bg-1 dark:bg-ondark-bg-1 group relative w-[200px] rounded-md"
    >
      <img
        src={event.frame}
        alt="Event frame"
        className="h-full w-full rounded-md object-cover hover:shadow-md"
      />
      <div className="flex items-center justify-between text-[9px]">
        <span>{event.name}</span>
        <span className="text-[8px]">
          {new Date(event.createdTime * 1000).toLocaleString()}
        </span>
      </div>
      <div className="invisible absolute top-1 right-2 group-hover:visible">
        <button
          onClick={toggleFullScreen}
          className="bg-ondark-bg-2 rounded-full opacity-80"
        >
          {isFullScreen ? (
            <FullScreenExitIcon className="fill-dark-text h-7 w-7" />
          ) : (
            <FullScreenIcon className="fill-dark-text h-5 w-5" />
          )}
        </button>
      </div>
    </div>
  );
}
