import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Camera } from '../types/camera';
import { SSEDatum } from '../types/event';
import { State } from './types';
import useMultiSSE from './useMultiSSE';
import { createStateManager } from './util';

export function useCameras(
  visionHosts: string[],
  onError: (message: string) => void,
  onNewEvent: (cameraId: string) => void
): [State, (cameraId: string) => void, (cameraId: string) => void] {
  const stateManager = useRef(createStateManager());
  const [state, setState] = useState<State>(stateManager.current.getState());

  useEffect(() => {
    stateManager.current.onNewEvent(onNewEvent);
  }, [onNewEvent]);

  useEffect(() => {
    // Update state every second to prevent too frequent updates
    const intervalId = window.setInterval(() => {
      setState(stateManager.current.getState());
    }, 1000);

    return () => {
      window.clearInterval(intervalId);
    };
  }, []);

  const onReceiveEvent = useCallback((datum: SSEDatum) => {
    const { id: cameraId } = datum;
    stateManager.current.receivedEvent(cameraId);
  }, []);

  const urls = useMemo(
    () => visionHosts.map((host) => `${host}/api/vision/events`),
    [visionHosts]
  );

  useMultiSSE(urls, onReceiveEvent);

  useEffect(() => {
    const init = async () => {
      try {
        const responses = await Promise.allSettled(
          visionHosts.map((host) =>
            fetch(`${host}/api/vision/cameras`).then((response) => {
              if (!response.ok) {
                throw new Error(`Failed to fetch from ${host}`);
              }
              return response.json();
            })
          )
        );

        const cameras = [] as Camera[];

        responses.forEach((result) => {
          if (result.status === 'fulfilled') {
            cameras.push(...result.value);
          }
        });

        stateManager.current.updateCameras(cameras);
      } catch (error) {
        onError('Failed to fetch cameras, try reloading the page');
      }
    };

    init();
  }, [visionHosts, onError]);

  const hideCamera = useCallback((cameraId: string) => {
    stateManager.current.hideCamera(cameraId);
    // updating state now to hide camera immediately
    setState(stateManager.current.getState());
  }, []);

  const snoozeCamera = useCallback((cameraId: string) => {
    stateManager.current.snoozeCamera(cameraId);
    // updating state now to hide camera immediately
    setState(stateManager.current.getState());
  }, []);

  return [state, hideCamera, snoozeCamera];
}
