import { useSendAudio } from '@hakimo-ui/hakimo/data-access';
import { toast } from '@hakimo-ui/hakimo/util';
import { Button } from '@hakimo-ui/shared/ui-base';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { MicrophoneIcon } from '@heroicons/react/24/solid';
import { useCallback, useState } from 'react';
import AudioUploadModal from './AudioUploadModal';
import Recorder from './Recorder';

interface Props {
  cameraId: string;
  cameraName?: string;
  alarmId?: string;
  locAlarmId?: string;
  onTalkdown: (cameraId: string, alarmId?: string) => void;
  onToggle?: (open: boolean) => void;
}

export function AudioRecorder(props: Props) {
  const {
    cameraId,
    alarmId,
    onTalkdown,
    onToggle,
    locAlarmId,
    cameraName = '',
  } = props;
  const [record, setRecord] = useState(false);
  const [audioFile, setAudioFile] = useState<File>();
  const [uploadModalVisible, setUploadModalVisible] = useState(false);

  const mutation = useSendAudio(cameraId, alarmId, locAlarmId, () => {
    onTalkdown(cameraId, alarmId || locAlarmId);
    toast('Audio sent to camera successfully');
  });

  const onSubmitAudio = useCallback(
    (file?: File) => {
      const formData = new FormData();
      formData.append('audio', file || '');
      mutation.mutate(formData);
    },
    [mutation]
  );

  const onCloseRecorder = useCallback(() => {
    setRecord(false);
    onToggle && onToggle(false);
  }, [onToggle]);

  const onOpenRecorder = () => {
    setRecord(true);
    onToggle && onToggle(true);
  };

  const showUploadModal = () => setUploadModalVisible(true);

  const onCloseAudioModal = () => setUploadModalVisible(false);

  const onSubmitAudioFromRecorder = () => onSubmitAudio(audioFile);

  return (
    <>
      <div className="flex items-center justify-end">
        {record ? (
          <Recorder
            onClose={onCloseRecorder}
            onChangeAudio={setAudioFile}
            onSubmitAudio={onSubmitAudioFromRecorder}
          />
        ) : (
          <span className="flex items-center gap-2">
            <Button
              variant="icon"
              onClick={showUploadModal}
              title="Audio talkdown audio upload"
            >
              <ArrowUpTrayIcon className="h-5 w-5" />
            </Button>
            <Button
              variant="icon"
              onClick={onOpenRecorder}
              title="Live camera audio talkdown"
            >
              <MicrophoneIcon className="h-5 w-5" />
            </Button>
          </span>
        )}
      </div>
      {uploadModalVisible && (
        <AudioUploadModal
          onClose={onCloseAudioModal}
          open={uploadModalVisible}
          cameraName={cameraName}
          onSubmitAudio={onSubmitAudio}
        />
      )}
    </>
  );
}

export default AudioRecorder;
