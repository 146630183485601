import {
  FilterConfig,
  LocationAlarmStatus,
  StatusFilterOption,
} from '@hakimo-ui/hakimo/types';
import { LocationAlarmFilterValues } from './types';

const statusOptions: StatusFilterOption<LocationAlarmStatus>[] = [
  { id: 'pending', name: 'Pending' },
  { id: 'resolved', name: 'Resolved' },
  { id: 'inProgress', name: 'In Progress' },
];

export const filterConfigs: FilterConfig[] = [
  {
    type: 'status',
    defaultValue: {
      values: [],
      negative: false,
    },
    options: statusOptions,
  },
  {
    type: 'location',
    defaultValue: {
      values: [],
      negative: false,
    },
  },
  {
    type: 'camera',
    defaultValue: {
      values: [],
      negative: false,
    },
  },
];

export const defaultFilterValues = filterConfigs.reduce((acc, config) => {
  return { ...acc, [config.type]: config.defaultValue };
}, {} as LocationAlarmFilterValues);

export const personDetectedOptions = [
  'Authorized Employee(s)/Cleaner(s)',
  'Authorized Security Guard',
  'Law Enforcement on site',
  'Person Off-site',
  'Deterred with Talkdown',
  'Other',
];

export const vehicleDetectedOptions = [
  'Employee Vehicle',
  'Security Vehicle',
  'Law Enforcement Vehicle',
  'Delivery Truck',
  'Vehicle Off-site',
  'Deterred with Talkdown',
  'Other',
];

export const incorrectDetectionOptions = [
  'No Person',
  'No Vehicle movement / Stationary Vehicle',
  'Animal onsite',
  'Other',
];
