import { Page } from '@hakimo-ui/hakimo/ui-layout';
import { useUser } from '@hakimo-ui/hakimo/util';
import { Alert } from '@hakimo-ui/shared/ui-base';
import { Monitoring } from './pages/Monitoring';

export function ScanRoot() {
  const user = useUser();

  return (
    <Page title="Scan" fullWidth>
      {user.visionTenants && user.visionTenants.length > 0 ? (
        <Monitoring visionTenants={user.visionTenants} />
      ) : (
        <Alert type="info">
          You do not currently have access to this page. Please contact the
          system administrator to request access permissions. Thank you!
        </Alert>
      )}
    </Page>
  );
}

export default ScanRoot;
