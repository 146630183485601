import { useEffect, useMemo, useRef, useState } from 'react';
import { createStreamManager } from './stream-manager';

interface Props {
  url: string;
}

export function WebrtcPlayer(props: Props) {
  const { url: livestreamUrl } = props;

  const url = !livestreamUrl.endsWith('/')
    ? `${livestreamUrl}/`
    : livestreamUrl;

  const [message, setMessage] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  const streamManager = useMemo(() => {
    return createStreamManager(url, videoRef, setMessage);
  }, [url]);

  useEffect(() => {
    streamManager.init();
    return () => {
      streamManager.cleanup();
    };
  }, [streamManager]);

  return (
    <div className="dark:bg-ondark-bg-2 bg-onlight-bg-2 relative w-full">
      <video
        ref={videoRef}
        controls
        muted
        playsInline
        autoPlay
        className="aspect-video w-full"
      />
      {message && (
        <div className="pointer-events-none absolute inset-0 box-border flex items-center justify-center p-5 text-center text-xs font-bold text-white [text-shadow:0_0_5px_black]">
          {message}
        </div>
      )}
    </div>
  );
}
